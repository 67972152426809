<template>
  <div
    class="text-input"
    :class="{
      'border-b border-white': !formInput,
      'px-16 flex-1': formInput && !newsletterModal,
    }"
  >
    <div :class="{ 'border-b-2 border-grey800 ': newsletterModal }">
      <vue-google-autocomplete
        :id="id"
        :value="input"
        class="input form-control font-normal"
        :class="{
          'border-warning focus:border-warning': !!error,
          'text-grey200': !formInput,
          'text-grey600 focus:text-grey800 valid:text-grey800 w-full':
            formInput,
        }"
        required="required"
        :type="type"
        :maxlength="maxLength"
        :inputmode="inputMode"
        :autocomplete="autocomplete"
        :tabindex="tabindex"
        :autofocus="autofocus"
        :country="countryCode"
        types="address"
        placeholder=""
        @blur="$emit('blur')"
        @keyup.enter="$emit('enter-submit')"
        @inputChange="setValue"
        @placechanged="getAddressData"
      >
      </vue-google-autocomplete>
      <label
        :for="id"
        class="flex absolute uppercase cursor-text select-none meta-info-text w-full pr-25 pr-28"
        :class="{
          'form-control-placeholder': !formInput,
          'form-input-control-placeholder': formInput,
          'text-grey200': !error && !formInput && !input,
          'text-grey600': !error && formInput && !input && !newsletterModal,
          'text-grey800': !error && !formInput && !input && newsletterModal,
          'text-error': !!error,
          'text-warning': !!warning,
          'has-input text-grey200': !!input && !error && !formInput,
          'has-input text-grey800': !!input && !error && formInput,
          'has-input text-error': !!input && !!error,
        }"
      >
        <fa
          v-if="error"
          class="mr-6 mt-2"
          :icon="['fas', 'exclamation-circle']"
        ></fa>
        <fa
          v-else-if="warning"
          class="mr-6 mt-2"
          :icon="['fas', 'exclamation-triangle']"
        ></fa>
        <span v-if="warning">
          {{ `${placeholder} - ${warning}` }}
        </span>
        <span v-else class="w-full">
          {{ !!error ? `${placeholder} - ${error}` : placeholder }}
        </span>
      </label>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
// @ts-ignore
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import debounce from '~/util/debounce.js';
import { ref, computed, onMounted, watch } from 'vue';
import { useGlobalContentStore } from '~/store/globalContent';
const config = useGlobalContentStore().config;

const props = defineProps<{
  placeholder: string,
  error?: string,
  warning?: string,
  formInput: boolean,
  newsletterModal?: boolean,
  type?: string,
  initValue?: string,
  id: string,
  delaySubmit?: boolean,
  maxLength?: number,
  autocomplete?: string,
  tabindex?: number,
  autofocus?: boolean,
  numberOnly?: boolean,
  prefix?: string,
  inputMode?: string,
}>();

const input = ref('');
const submitInputDebounced = ref(() => {});

const emit = defineEmits<{
  (event: 'submitInput', value: string): void,
  (event: 'setCityValue', value: any): void,
  (event: 'setPostalValue', value: any): void,
  (event: 'placechanged', value: any): void,
  (event: 'blur'): void,
  (event: 'enter-submit'): void,
  (event: 'update'): void,
}>();

onMounted(() => {
  submitInputDebounced.value = debounce(submitInput, props.delaySubmit ? 800 : 300);
  input.value = props.initValue;
});

watch(input, () => {
  submitInputDebounced.value();
});

const submitInput= () => {
  emit('submitInput', input.value);
};

const countryCode = computed(() => {
  return config.currentMarket?.countryCode;
});

const setValue= (val: any) => {
  input.value = val.newVal;
};

const getAddressData = (addressData: any, placeResultData: any) => {
  const inputVal = document.getElementById(props.id) as HTMLInputElement;
  if (addressData.route && addressData.street_number) {
    inputVal.value = addressData.route + ' ' + addressData.street_number;
  } else if (addressData.route) {
    inputVal.value = addressData.route;
  }
  input.value = inputVal.value;

  // Set other data in address form
  placeResultData.address_components.forEach((component: any) => {
    const types = component.types;
    if (types.includes('postal_town')) {
      updateCityValue(component);
    }
    if (types.includes('postal_code')) {
      updatePostalValue(component);
    }
  });
};

const updateCityValue= (component: any) => {
  const prefix = props.prefix !== undefined ? props.prefix : '';
  const input = document.getElementById(
    'intPersonCity' + prefix
  ) as HTMLInputElement;
  const label = input.nextElementSibling;
  if (input) {
    input.value = component.long_name;
    emit('setCityValue', component.long_name);
    if (input.value) {
      label?.classList.add('has-input');
    } else {
      label?.classList.remove('has-input');
    }
  }
};
  
const updatePostalValue= (component: any) => {
  const prefix = props.prefix !== undefined ? props.prefix : '';
  const input = document.getElementById(
    'intPersonPostalCode' + prefix
  ) as HTMLInputElement;
  const label = input.nextElementSibling;
  if (input) {
    input.value = component.long_name;
    emit('setPostalValue', component.long_name);
    if (input.value) {
      label?.classList.add('has-input');
    } else {
      label?.classList.remove('has-input');
    }
  }
};
  
</script>

<style scoped>
.text-input {
  @apply relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    transition: all 5000s ease-in-out 0s;
  }
}

.form-control {
  height: 56px;
  padding-top: 16px;
  padding-bottom: 4px;
  appearance: none;
  border: 0;
  outline: none;
  background: transparent;

  @apply text-base w-full;
}

.form-input-control-placeholder.text-error:not(.has-input) {
  @apply top-1/2 transform -translate-y-1/2;
}

.form-control-placeholder,
.form-input-control-placeholder {
  transition: all 200ms;
  top: 20px;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
}

.form-control:focus + .form-input-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
  top: 20px;
}

.has-input {
  font-size: 75%;
  transform: translate3d(0, -75%, 0);
}
</style>
